<template>
    <div class="container">
        <van-row gutter="20">
            <van-col span="8" v-if="!withOutLeftSide" class="pos-sticky">
                <CategoryList />
            </van-col>
            <van-col :span="withOutLeftSide ? 24 : 12">
                <router-view />
            </van-col>
        </van-row>
    </div>
</template>

<script>
export default {
    name: 'App',
    data() {
        return {
            // colorScheme: window.Telegram.WebApp.colorScheme,
        };
    },
    computed: {
        withOutLeftSide() {
            let arr = ['Setting'];
            return arr.includes(this.$route.name);
        },
    },
    components: {
        CategoryList: () => import('@/components/Category/List.vue'),
    },
    created() {
        // window.Telegram.WebApp.expand();
    },
    mounted() {
        // document.body.classList.add(`${this.colorScheme}-scheme`);
    },
};
</script>

<style lang="scss">
@import './assets/normalize.css';

.pos-sticky {
    position: sticky;
    top: 10px;
}
</style>
