import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from '@/i18n'


import Vant from 'vant';
Vue.use(Vant);
import 'vant/lib/index.css';



import "@/assets/scss/style.scss";

export const eventBus = new Vue(); // creating an event bus.


new Vue({
    el: "#app",
    router: router,
    store,
    i18n,
    render: h => h(App),
})


