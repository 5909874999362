import ApiCategory from '@/api/category';
export default {
    namespaced: true,

    state: {
        categoryList: [],
        choosenCategory: {},
    },

    getters: {
        categoryList: (state) => state.categoryList,
        choosenCategory: (state) => state.choosenCategory,
    },

    mutations: {
        SET_CATEGORY_LIST: (state, payload) => {
            state.categoryList = payload;
        },
        SET_CATEGORY: (state, payload) => {
            state.choosenCategory = payload;
        },
    },
    actions: {
        getCategoryList({ commit }) {
            return ApiCategory.getCategoryList().then((responce) => {
                commit("SET_CATEGORY_LIST", responce.data)
                return responce.data
            });
        },
        setCategory({ commit }, item) {
            commit("SET_CATEGORY", item)
        },
    }
};
