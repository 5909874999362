import ApiTasks from "@/api/tasks";

export default {
    namespaced: true,

    state: {
        task: {},
        taskList: [],
    },

    getters: {
        task: (state) => state.task,
        taskList: (state) => state.taskList,
    },

    mutations: {
        SET_TASK: (state, payload) => {
            state.task = payload;
        },
        SET_TASK_LIST: (state, payload) => {
            state.taskList = payload;
        },
    },
    actions: {
        getListTask({ commit }, params) {
            return ApiTasks.getTasksByCategory(params).then((responce) => {
                commit("SET_TASK_LIST", responce.data)
                return responce.data
            });
        },
        setTask({ commit }, item) {
            commit("SET_TASK", item)
        },
    }
};
