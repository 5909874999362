import Vue from 'vue'
import Vuex from 'vuex'
import Task from './module/Task'
import Category from './module/Category'


Vue.use(Vuex)

const store = new Vuex.Store({
  namespaced: true,
  modules: {
    Task,
    Category
  }
});


export default store

